"use client";

import React, { forwardRef, useRef } from "react";
import { AnimatedBeam } from "./AnimatedBeamSetUp";
import { cx } from "class-variance-authority";

import royal from "../../../../../public/Img/Home/animated Beam/royal.svg";
import illuvium from "../../../../../public/Img/Home/animated Beam/illuvium.svg";
import ledger from "../../../../../public/Img/Home/animated Beam/ledger.svg";
import rainbow from "../../../../../public/Img/Home/animated Beam/rainbow.svg";
import zapper from "../../../../../public/Img/Home/animated Beam/zapper.svg";
import draftKings from "../../../../../public/Img/Home/animated Beam/draftKings.svg";

import tan_theta_logo from "../../../../../public/Img/Home/animated Beam/tan_theta_logo.svg";

import Image from "next/image";

import polygon from "../../../../../public/Img/Home/animated Beam/polygon.svg";
import solana from "../../../../../public/Img/Home/animated Beam/solana.svg";
import ethereum from "../../../../../public/Img/Home/animated Beam/ethereum.svg";
import cardano from "../../../../../public/Img/Home/animated Beam/cardano.svg";
import polkadot from "../../../../../public/Img/Home/animated Beam/polkadot.svg";
import xrp from "../../../../../public/Img/Home/animated Beam/xrp.svg";

const Circle = forwardRef(({ className, children }, ref) => {
    return (
        <div
            ref={ref}
            className={cx(
                "z-10 flex size-20 items-center justify-center rounded-full border-2 bg-white p-1 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
                className
            )}
        >
            {children}
        </div>
    );
});
Circle.displayName = "Circle";

const Square = forwardRef(({ className, children }, ref) => {
    return (
        <div
            ref={ref}
            className={cx(
                "z-10 flex overflow-hidden rounded-xl border-2 bg-white shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)] w-32 h-28  ",
                className
            )}
        >
            {children}
        </div>
    );
});
Square.displayName = "Square";

export function AnimatedBeamMultipleOutputDemo({ className }) {
    const containerRef = useRef(null);
    const div1Ref = useRef(null);
    const div2Ref = useRef(null);
    const div3Ref = useRef(null);
    const div4Ref = useRef(null);
    const div5Ref = useRef(null);
    const div6Ref = useRef(null);
    const div7Ref = useRef(null);
    const div8Ref = useRef(null);
    const div9Ref = useRef(null);
    const div10Ref = useRef(null);
    const div11Ref = useRef(null);
    const div12Ref = useRef(null);
    const div13Ref = useRef(null);

    return (
        <div
            className={cx(
                "relative flex h-[1000px] w-full items-center justify-center overflow-hidden",
                className
            )}
            ref={containerRef}
        >
            <div className="relative flex flex-col items-center gap-10">
                {/* Top-right positioned icon */}
                <div className="absolute -top-[400px] z-50 flex gap-4">
                    <Square ref={div8Ref}>
                        <div className="flex flex-col w-full">
                            <div className="bg-[#410EAD] text-center">
                                <span className="text-white font-manrope font-bold text-base leading-4">
                                    NFTS
                                </span>
                            </div>
                            <div className="flex flex-col justify-center items-center gap-2 overflow-hidden z-50">
                                <div className="flex justify-center items-center mt-4">
                                    <Image
                                        src={royal}
                                        alt="royal"
                                        width={52}
                                        height={52}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-1">
                                    <Image
                                        src={ledger}
                                        alt="ledger"
                                        width={52}
                                        height={52}
                                    />
                                    <Image
                                        src={illuvium}
                                        alt="illuvium"
                                        width={52}
                                        height={52}
                                    />
                                </div>
                            </div>
                        </div>
                    </Square>

                    <Square ref={div9Ref}>
                        <div className="flex flex-col w-full">
                            <div className="bg-[#410EAD] text-center">
                                <span className="text-white font-manrope font-bold text-base leading-4">
                                    Defi
                                </span>
                            </div>
                            <div className="flex flex-col justify-center items-center gap-2 overflow-hidden z-50">
                                <div className="flex justify-center items-center mt-4">
                                    <Image
                                        src={draftKings}
                                        alt="draftKings"
                                        width={40}
                                        height={40}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-1">
                                    <Image
                                        src={zapper}
                                        alt="zapper"
                                        width={52}
                                        height={52}
                                    />
                                    <Image
                                        src={rainbow}
                                        alt="rainbow"
                                        width={52}
                                        height={52}
                                    />
                                </div>
                            </div>
                        </div>
                    </Square>

                    <Square ref={div10Ref}>
                        <div className="flex flex-col w-full">
                            <div className="bg-[#410EAD] text-center">
                                <span className="text-white font-manrope font-bold text-base leading-4">
                                    Wallets
                                </span>
                            </div>
                            <div className="flex flex-col justify-center items-center gap-2 overflow-hidden z-50">
                                <div className="flex justify-center items-center mt-4">
                                    <Image
                                        src={royal}
                                        alt="royal"
                                        width={52}
                                        height={52}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-1">
                                    <Image
                                        src={ledger}
                                        alt="ledger"
                                        width={52}
                                        height={52}
                                    />
                                    <Image
                                        src={illuvium}
                                        alt="illuvium"
                                        width={52}
                                        height={52}
                                    />
                                </div>
                            </div>
                        </div>
                    </Square>

                    <Square ref={div11Ref}>
                        <div className="flex flex-col w-full">
                            <div className="bg-[#410EAD] text-center">
                                <span className="text-white font-manrope font-bold text-base leading-4">
                                    Gaming
                                </span>
                            </div>
                            <div className="flex flex-col justify-center items-center gap-2 overflow-hidden z-50">
                                <div className="flex justify-center items-center mt-4">
                                    <Image
                                        src={draftKings}
                                        alt="draftKings"
                                        width={40}
                                        height={40}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-1">
                                    <Image
                                        src={zapper}
                                        alt="zapper"
                                        width={52}
                                        height={52}
                                    />
                                    <Image
                                        src={rainbow}
                                        alt="rainbow"
                                        width={52}
                                        height={52}
                                    />
                                </div>
                            </div>
                        </div>
                    </Square>

                    <Square ref={div12Ref}>
                        <div className="flex flex-col w-full">
                            <div className="bg-[#410EAD] text-center">
                                <span className="text-white font-manrope font-bold text-sm leading-4">
                                    Infra & Tooling
                                </span>
                            </div>
                            <div className="flex flex-col justify-center items-center gap-2 overflow-hidden z-50">
                                <div className="flex justify-center items-center mt-4">
                                    <Image
                                        src={draftKings}
                                        alt="draftKings"
                                        width={40}
                                        height={40}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-1">
                                    <Image
                                        src={zapper}
                                        alt="zapper"
                                        width={52}
                                        height={52}
                                    />
                                    <Image
                                        src={rainbow}
                                        alt="rainbow"
                                        width={52}
                                        height={52}
                                    />
                                </div>
                            </div>
                        </div>
                    </Square>

                    <Square ref={div13Ref}>
                        <div className="flex flex-col w-full">
                            <div className="bg-[#410EAD] text-center">
                                <span className="text-white font-manrope font-bold text-base leading-4">
                                    Web2
                                </span>
                            </div>
                            <div className="flex flex-col justify-center items-center gap-2 overflow-hidden z-50">
                                <div className="flex justify-center items-center mt-4">
                                    <Image
                                        src={royal}
                                        alt="royal"
                                        width={52}
                                        height={52}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-1">
                                    <Image
                                        src={ledger}
                                        alt="ledger"
                                        width={52}
                                        height={52}
                                    />
                                    <Image
                                        src={illuvium}
                                        alt="illuvium"
                                        width={52}
                                        height={52}
                                    />
                                </div>
                            </div>
                        </div>
                    </Square>
                </div>

                {/* Center icon */}
                <div className="absolute z-50 -top-10">
                    {/* <div className="absolute inset-0 flex items-center justify-center">
                        <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#410EAD] opacity-75"></div>
                    </div> */}
                    <Circle
                        ref={div7Ref}
                        className="size-28 relative z-10 ring-[#410EAD] ring-offset-2 ring-4"
                    >
                        <Image
                            src={tan_theta_logo}
                            alt="tan_theta_logo"
                            width={100}
                            height={100}
                            className="size-28 w-40 h-40"
                        />
                    </Circle>
                </div>

                {/* Bottom-left icons in a diagonal */}
                <div className="relative -bottom-64 flex flex-row items-center gap-2 z-50">
                    <Circle ref={div1Ref}>
                        <Image
                            src={polygon}
                            alt="polygon"
                            width={80}
                            height={80}
                        />
                    </Circle>
                    <Circle ref={div2Ref}>
                        <Image
                            src={solana}
                            alt="solana"
                            width={80}
                            height={80}
                        />
                    </Circle>
                    <Circle ref={div3Ref}>
                        <Image
                            src={ethereum}
                            alt="ethereum"
                            width={80}
                            height={80}
                        />
                    </Circle>
                    <Circle ref={div4Ref}>
                        <Image
                            src={cardano}
                            alt="cardano"
                            width={80}
                            height={80}
                        />
                    </Circle>
                    <Circle ref={div5Ref}>
                        <Image
                            src={polkadot}
                            alt="polkadot"
                            width={80}
                            height={80}
                        />
                    </Circle>
                    <Circle ref={div6Ref}>
                        <Image src={xrp} alt="xrp" width={80} height={80} />
                    </Circle>
                </div>
            </div>

            {/* AnimatedBeams */}
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div1Ref}
                toRef={div7Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div2Ref}
                toRef={div7Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div3Ref}
                toRef={div7Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div4Ref}
                toRef={div7Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div5Ref}
                toRef={div7Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div6Ref}
                toRef={div7Ref}
                duration={3}
            />

            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div8Ref}
                toRef={div7Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div9Ref}
                toRef={div7Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div10Ref}
                toRef={div7Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div11Ref}
                toRef={div7Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div12Ref}
                toRef={div7Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div13Ref}
                toRef={div7Ref}
                duration={3}
            />
        </div>
    );
}
