import React from 'react'
import MainContactPopup from './MainContactPopup';
import { notFound } from "next/navigation";
export const dynamic = "force-dynamic";
import { getContactInfoTags } from "@/app/components/ui/common/hooks";

const ContactPopup = async () => {
  const contactInfoTags = await getContactInfoTags();
  if (!contactInfoTags) return notFound();

  return (
    <>
      <MainContactPopup contactInfoTags={contactInfoTags} />
    </>
  )
}

export default ContactPopup
