import React, { useContext, useEffect, useState } from "react";
import Link from "next/link";
import MyContext from "../../Context/MyContext.js";

const ProjectHour = () => {
    const {
        technologies,
        setTechnologies,
        modifiedHourlyData,
        setModifiedHourlyData,
        modifiedMonthlyData,
        setModifiedMonthlyData,
    } = useContext(MyContext);

    const backgroundStyle = {
        backgroundImage:
            "linear-gradient(98.46deg, #410EAD -1.13%, #7B3FE4 98.96%)",
    };

    return (
        <>
            <div className="flex flex-col gap-5 mt-5">
                <div className="text-xl font-bold h-10">For Hourly</div>
                <div className="md:grid md:grid-cols-3 md:gap-3 flex flex-col gap-y-3 lg:grid lg:grid-cols-3 xl:grid xl:grid-cols-5">
                    {modifiedHourlyData?.map((data) => (
                        <div
                            className="bg-white p-4 rounded-md grid place-items-center xl:h-44"
                            style={{
                                boxShadow: "0px 0px 38px 0px #7B3FE429",
                            }}
                            key={data?.id}
                        >
                            <div className="lg:text-sm 2xl:text-base">
                                {data?.title}
                            </div>
                            <div className="text-[#410EAD] font-bold text-xl md:text-[17px] lg:text-lg border-t-2 border-b-2 horgintalGradientBorder py-2 ">
                                {data?.hour}
                            </div>
                            <div className="lg:text-sm 2xl:text-base">
                                {data?.days}
                            </div>
                        </div>
                    ))}

                    <div
                        className="grid gap-5 place-items-center font-bold py-5 rounded-xl text-white  text-center col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-2   md:block xl:py-10"
                        style={backgroundStyle}
                    >
                        <h2 className="text-lg lg:text-xl mb-6">
                            Want to start a Project?
                        </h2>
                        <Link
                            href="https://www.tanthetaa.com/contact"
                            className="px-5 py-1 border-2 border-white rounded-md mt-6 hover:text-black hover:bg-[#29e248] ease-in-out delay-100"
                        >
                            Get a Free Quote
                        </Link>
                    </div>
                </div>
            </div>

            <div className="flex flex-col ">
                <div className="text-xl font-bold h-10">
                    For Dedicated Developer
                </div>
                <div
                    className=" my-5 h-auto flex flex-col gap-3 gap-y-3  md:grid md:grid-cols-3 xl:grid-cols-5"
                    style={{
                        width: "",
                        height: "-webkit-fill-available",
                    }}
                >
                    {modifiedMonthlyData?.map((data) => (
                        <div
                            className="bg-white p-4 rounded-md grid place-items-center xl:h-44 "
                            style={{
                                boxShadow: "0px 0px 38px 0px #7B3FE429",
                            }}
                            key={data?.id}
                        >
                            <div>{data?.title}</div>
                            <div className="text-[#410EAD] font-bold text-lg border-t-2 border-b-2 py-2 2xl:text-base xl:text-sm horgintalGradientBorder ">
                                {data?.hour}
                            </div>
                            <div className="text-sm">{data?.experience}</div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ProjectHour;
