import { useState, useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css"; // Use any Prism theme you like
import "prismjs/components/prism-solidity"; // Load Solidity syntax highlighting
import "./CodeSnippet.css";

const CodeSnippet = ({ fullCode }) => {
    const [displayedCode, setDisplayedCode] = useState(""); // Code that is being animated
    const [initialCode, setInitialCode] = useState(""); // First 7 lines of code
    const [remainingCode, setRemainingCode] = useState(""); // Code starting from line 8
    const [highlightedCode, setHighlightedCode] = useState(""); // Code with syntax highlighting
    const [animationComplete, setAnimationComplete] = useState(false); // Track if animation is complete

    useEffect(() => {
        // Split the code into lines
        const codeLines = fullCode.split("\n");
        const firstTenLines = codeLines.slice(0, 8).join("\n"); // First 10 lines
        const remainingLines = codeLines.slice(8).join("\n"); // Remaining lines starting from line 11

        setInitialCode(firstTenLines); // Display the first 10 lines immediately
        setRemainingCode(remainingLines); // The remaining code will be animated

        // Highlight the initial code
        const highlightedInitialCode = Prism.highlight(
            firstTenLines,
            Prism.languages.solidity, // Use Solidity language
            "solidity"
        );
        setHighlightedCode(highlightedInitialCode);
    }, [fullCode]);

    useEffect(() => {
        if (animationComplete) {
            // Restart animation if it was previously completed
            setDisplayedCode("");
            setAnimationComplete(false);
        }

        let index = 0;
        const intervalId = setInterval(() => {
            if (index < remainingCode.length) {
                setDisplayedCode((prev) => prev + remainingCode[index]);
                index++;
            } else {
                clearInterval(intervalId);
                setAnimationComplete(true); // Mark animation as complete
            }
        }, 50); // Typing speed (50 ms per character)

        return () => clearInterval(intervalId); // Clear interval when component unmounts
    }, [remainingCode, animationComplete]);

    useEffect(() => {
        // Combine initial and displayed code for highlighting
        const combinedCode = initialCode + displayedCode;

        // Remove any unwanted characters or "undefined" from combinedCode
        const cleanedCode = combinedCode.replace(/undefined/g, "");

        const highlightedCode = Prism.highlight(
            cleanedCode,
            Prism.languages.solidity, // Use Solidity language
            "solidity"
        );
        setHighlightedCode(highlightedCode);
    }, [displayedCode, initialCode]);

    return (
        <div
            style={{
                fontFamily: "monospace",
                background: "#2d2d2d",
                color: "#f8f8f2",
                padding: "20px",
                borderRadius: "10px",
                whiteSpace: "pre-wrap", // Preserve whitespace and line breaks
                height: "550px",
                overflowY: "auto", // Enable vertical scrolling
                overflowX: "auto", // Enable horizontal scrolling only when necessary
            }}
        >
            <pre
                className="language-solidity"
                dangerouslySetInnerHTML={{ __html: highlightedCode }}
                style={{
                    margin: "0",
                    padding: "0",
                    borderRadius: "0",
                }}
            />
        </div>
    );
};

export default CodeSnippet;
