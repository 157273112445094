"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import CountUp from "react-countup";
import { FaArrowRightLong } from "react-icons/fa6";

import cowProtocolLogo from "../../../public/Img/Home/cowProtocolLogo.png";
import cowProtocoImg from "../../../public/Img/Home/cowProtocoImg.png";
import kucoinLogo from "../../../public/Img/Home/kucoinLogo.png";
import kucoinImg from "../../../public/Img/Home/kucoinImg.png";
import pimlicoLogo from "../../../public/Img/Home/pimlicoLogo.png";
import pimlicoImg from "../../../public/Img/Home/pimlicoImg.png";

export const OurRecentWork = () => {
    const content = {
        title: "Our Recent Work",
        subtitle: "",
        services: [
            {
                id: 1,
                logo: cowProtocolLogo,
                textTitle: "NFT DEVELOPMENT",
                text: "Transform  your digital creations into valuable assets.We specialize in crafting unique NFTs that capture attention and drive market success.",
                image: cowProtocoImg,
                imageLink:
                    "https://www.tanthetaa.com/portfolio/decentrilized-exchange-like-cowswap",
                descriptionNumber: "2500+",
                descriptionText: "",
                subDescription: "Projects Delivered",
                viewMore: "View More -->",
                viewMoreLink: "https://www.tanthetaa.com/portfolio",
                btnName: "",
                btnLink: "",
                subDesColor: "bg-[#FFFFFF]",
                ViewCaseStudyColor: "white",
            },
            {
                id: 2,
                logo: kucoinLogo,
                textTitle: "CRYPTO DEVELOPMENT",
                text: "Build powerful ,secure and scalable crypto platforms.From exchanges to wallets,we deliver innovative solutions for the digital currency ecosystem.",
                image: kucoinImg,
                imageLink:
                    "https://www.tanthetaa.com/portfolio/mobileapp-like-kucoin",
                descriptionNumber: "1600+",
                descriptionText: "",
                subDescription: "Happy Clients",
                viewMore: "",
                viewMoreLink: "",
                btnName: "",
                btnLink: "",
                subDesColor: "bg-[#FFFFFF]",
                ViewCaseStudyColor: "black",
            },
            {
                id: 3,
                logo: pimlicoLogo,
                textTitle: "BLOCKCHAIN DEVELOPMENT",
                text: "Unlock the potential of blockchain technology.We create custom solutions for decentralized applications,smart contracts and enterprise-grade blockchain.",
                image: pimlicoImg,
                imageLink: "https://www.tanthetaa.com/portfolio/pimlico",
                descriptionNumber: "",
                descriptionText: "Want to start a Project?",
                subDescription: "",
                viewMore: "",
                viewMoreLink: "",
                btnName: "Get a Free Quote",
                btnLink: "https://www.tanthetaa.com/contact",
                subDesColor: "bg-linear",
                ViewCaseStudyColor: "white",
            },
        ],
    };

    const backgroundStyle = {
        backgroundImage:
            "linear-gradient(98.46deg, #410EAD -1.13%, #7B3FE4 98.96%)",
    };
    const boxShadow = {
        boxShadow: "0px 0px 28px 0px rgba(151, 151, 151, 0.16)",
    };

    return (
        <div className="container mx-auto my-10 lg:my-[110px] px-4 md:px-24 lg:px-24 xl:px-0">
            <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold pb-16 text-center font-manrope">
                {content?.title}
            </h2>
            <div className="grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2 gap-10 justify-center">
                {content?.services?.map((ele, index) => {
                    return (
                        <div className="" key={index}>
                            <Link href={ele?.imageLink && ele?.imageLink}>
                                <div className="flex flex-col justify-end bg-[#F8F3FF] rounded-lg imageHover relative overflow-hidden">
                                    <Image
                                        src={ele?.logo}
                                        alt={ele?.logo}
                                        width={100}
                                        height={100}
                                        className="mx-auto py-6 px-5 w-64 h-20 cursor-pointer text-center object-contain"
                                    />
                                    <div className="flex flex-col gap-5 my-5 xl:px-8 lg:px-5 md:px-3 px-2 justify-center text-black items-center">
                                        <span className="text-2xl text-center font-extrabold leading-7">
                                            {ele?.textTitle}
                                        </span>
                                        <p className="text-base text-center font-medium leading-5">
                                            {ele?.text}
                                        </p>
                                    </div>
                                    <Image
                                        src={ele?.image}
                                        alt={ele?.image}
                                        width={300}
                                        height={250}
                                        className="mx-auto cursor-pointer text-end md:pl-4 pl-0 pt-9 w-full h-[300px]"
                                    />
                                    <div
                                        className={`${
                                            ele?.ViewCaseStudyColor === "black"
                                                ? "text-black"
                                                : "text-white"
                                        } font-medium text-base leading-4 justify-center items-center flex flex-row gap-2 absolute bottom-0 right-0 py-3 px-8 bg-gradient-to-l from-[#7B3FE4]`}
                                    >
                                        <span>View Case Study</span>
                                        <FaArrowRightLong />
                                    </div>
                                </div>
                            </Link>
                            <div
                                className={`grid gap-2 place-items-center font-bold py-9 lg:py-12 rounded-xl mt-4 h-44`}
                                style={{
                                    ...boxShadow,
                                    background:
                                        ele?.subDesColor === "bg-linear"
                                            ? backgroundStyle.backgroundImage
                                            : "#FFFFFF",
                                }}
                            >
                                {ele?.descriptionNumber && (
                                    <h2 className="text-2xl font-bold md:text-3xl xl:text-4xl">
                                        <CountUp
                                            start={0}
                                            end={parseInt(
                                                ele?.descriptionNumber
                                            )}
                                            duration={2.75}
                                            suffix="+"
                                            enableScrollSpy
                                        />
                                    </h2>
                                )}

                                {ele?.descriptionText && (
                                    <h2 className="text-lg md:text-xl xl:text-2xl text-white">
                                        {ele?.descriptionText}
                                    </h2>
                                )}

                                {ele?.subDescription && (
                                    <p className="text-xl font-semibold leading-6 text-[#323232]">
                                        {ele?.subDescription}
                                    </p>
                                )}

                                {ele?.viewMore && (
                                    <Link
                                        href={
                                            ele?.viewMoreLink &&
                                            ele?.viewMoreLink
                                        }
                                    >
                                        <p className="text-base font-medium leading-4 text-[#410EAD] hover:text-[#29e248] cursor-pointer">
                                            {ele?.viewMore}
                                        </p>
                                    </Link>
                                )}

                                {ele?.btnName && (
                                    <Link href={ele?.btnLink && ele?.btnLink}>
                                        <button className="text-lg font-semibold leading-5 border border-white text-white rounded-md py-1.5 px-8 bg-transparent hover:bg-[#29e248] hover:text-black transition delay-150 ease-in-out duration-300">
                                            {ele?.btnName}
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default OurRecentWork;
