"use client";

import React, { forwardRef, useRef } from "react";
import { cx } from "class-variance-authority";
import { AnimatedBeam } from "./AnimatedBeamSetUp";

import polygon from "../../../../../public/Img/Home/animated Beam/polygon.svg";
import solana from "../../../../../public/Img/Home/animated Beam/solana.svg";
import ethereum from "../../../../../public/Img/Home/animated Beam/ethereum.svg";
import cardano from "../../../../../public/Img/Home/animated Beam/cardano.svg";
import polkadot from "../../../../../public/Img/Home/animated Beam/polkadot.svg";
import xrp from "../../../../../public/Img/Home/animated Beam/xrp.svg";
import tan_theta_logo from "../../../../../public/Img/Home/animated Beam/tan_theta_logo.svg";
import Image from "next/image";

const Circle = forwardRef(({ className, children }, ref) => {
    return (
        <div
            ref={ref}
            className={cx(
                "z-10 flex size-[50px] items-center justify-center rounded-full border-2 bg-white p-1.5 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
                className
            )}
        >
            {children}
        </div>
    );
});
Circle.displayName = "Circle";

const TagCircle = forwardRef(({ className, children }, ref) => {
    return (
        <div
            ref={ref}
            className={cx(
                "z-10 flex w-20 items-center justify-center rounded-xl border-2 text-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)] bg-[#410EAD] ",
                className
            )}
        >
            {children}
        </div>
    );
});
TagCircle.displayName = "TagCircle";

export function AnimatedBeamMultipleHorizontal({ className }) {
    const containerRef = useRef(null);
    const div1Ref = useRef(null);
    const div2Ref = useRef(null);
    const div3Ref = useRef(null);
    const div4Ref = useRef(null);
    const div5Ref = useRef(null);
    const div6Ref = useRef(null);
    const div7Ref = useRef(null);
    const div8Ref = useRef(null);
    const div9Ref = useRef(null);
    const div10Ref = useRef(null);
    const div11Ref = useRef(null);
    const div12Ref = useRef(null);
    const div13Ref = useRef(null);

    return (
        <div
            className={cx(
                "relative flex h-[500px] w-full items-center justify-center overflow-hidden bg-background p-10",
                className
            )}
            ref={containerRef}
        >
            <div className="flex size-full max-w-lg flex-row items-stretch justify-between gap-10">
                <div className="flex flex-col justify-center gap-3 font-manrope font-extrabold text-xs text-center">
                    <TagCircle ref={div1Ref}>NFTS</TagCircle>
                    <TagCircle ref={div2Ref}>Defi</TagCircle>
                    <TagCircle ref={div3Ref}>Wallets</TagCircle>
                    <TagCircle ref={div4Ref}>Gaming</TagCircle>
                    <TagCircle ref={div5Ref}>Infra & Tooling</TagCircle>
                    <TagCircle ref={div6Ref}>Web2</TagCircle>
                </div>

                <div className="flex flex-col justify-center relative">
                    {/* <div className="absolute inset-0 flex items-center justify-center">
                        <div className="animate-ping absolute inline-flex size-16 rounded-full bg-[#410EAD] opacity-75"></div>
                    </div> */}
                    <Circle
                        ref={div7Ref}
                        className="size-16 relative z-10 ring-[#410EAD] ring-offset-2 ring-4"
                    >
                        <Image
                            src={tan_theta_logo}
                            alt="tan_theta_logo"
                            width={100}
                            height={100}
                            className="size-28 w-40 h-40"
                        />
                    </Circle>
                </div>

                <div className="flex flex-col justify-center gap-2">
                    <Circle ref={div8Ref}>
                        <Image
                            src={polygon}
                            alt="polygon"
                            width={80}
                            height={80}
                        />
                    </Circle>
                    <Circle ref={div9Ref}>
                        <Image
                            src={solana}
                            alt="solana"
                            width={80}
                            height={80}
                        />
                    </Circle>
                    <Circle ref={div10Ref}>
                        <Image
                            src={ethereum}
                            alt="ethereum"
                            width={80}
                            height={80}
                        />
                    </Circle>
                    <Circle ref={div11Ref}>
                        <Image
                            src={cardano}
                            alt="cardano"
                            width={80}
                            height={80}
                        />
                    </Circle>
                    <Circle ref={div12Ref}>
                        <Image
                            src={polkadot}
                            alt="polkadot"
                            width={80}
                            height={80}
                        />
                    </Circle>
                    <Circle ref={div13Ref}>
                        <Image src={xrp} alt="xrp" width={80} height={80} />
                    </Circle>
                </div>
            </div>

            {/* AnimatedBeams */}
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div1Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div2Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div3Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div4Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div5Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div6Ref}
                duration={3}
            />

            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div8Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div9Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div10Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div11Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div12Ref}
                duration={3}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div7Ref}
                toRef={div13Ref}
                duration={3}
            />
        </div>
    );
}
