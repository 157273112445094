export const BackendDataTech = [
    {
        id: 1,
        title: "Laravel",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 2,
        title: "CakePHP",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 3,
        title: "CodeIgniter",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 4,
        title: "NodeJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 5,
        title: "Django",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },

    {
        id: 6,
        title: "Flask",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 7,
        title: "GoLang",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 8,
        title: "Ruby on Rails",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 9,
        title: "NestJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 10,
        title: "ExpressJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 11,
        title: "MeteorJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 12,
        title: "SpringBoot",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 13,
        title: "PHP",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 14,
        title: "Scala",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
];

export const FrontendDataTech = [
    {
        id: 1,
        title: "ReactJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 2,
        title: "AngularJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 3,
        title: "JavaScript",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 4,
        title: "VueJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 5,
        title: "Typescript",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 6,
        title: "NextJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 7,
        title: "GatsbyJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },

    {
        id: 8,
        title: "ThreeJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 9,
        title: "HTML5",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 10,
        title: "CSS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 11,
        title: "Tailwind CSS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 12,
        title: "BootStrap",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 13,
        title: "FabricJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 14,
        title: "ChartJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },

    {
        id: 15,
        title: "PaperJS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
        id: 16,
        title: "D3JS",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
];

export const MobileTech = [
    {
        id: 1,

        title: "Flutter (Dart)",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 2,

        title: "Android Java",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 3,

        title: "Android Kotlin",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 4,

        title: "iOS SWift",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 5,

        title: "ReactNative",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $12/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $11/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $10/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1800/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2000/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2200/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3000/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
];

export const UIUXDataTech = [
    {
        id: 1,
        title: "Adobe Photoshop",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 2,
        title: "Figma",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 3,
        title: "Adobe XD",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 4,
        title: "Sketch",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 5,
        title: "Invision",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 6,
        title: "Zeplin",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 7,
        title: "Miro",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 8,
        title: "Adobe Illustrator",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 9,
        title: "Adobe Animate",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 10,
        title: "Adobe Express",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 11,
        title: "Adobe After effect",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $11/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $10/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $8/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $1200/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $1500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $1800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $2000/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $2500/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
];

export const BlockchainDataTech = [
    {
        id: 1,
        title: "Solidity",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 2,
        title: "Rust",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 3,
        title: "Tokenomics",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 4,
        title: "Cardano development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 5,
        title: "Ethereum development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },

    {
        id: 6,
        title: "Polygon development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 7,
        title: "Solana",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 8,
        title: "Hyperledger",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 9,
        title: "Polkadot",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },

    {
        id: 10,
        title: "Wallet Development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 11,
        title: "AA or ERC 4337",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 12,
        title: "Smart Contract Development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 13,
        title: "DApp Development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 14,
        title: "DeFI Development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },

    {
        id: 15,
        title: "NFT MarketplaceDevelopment",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 16,
        title: "DEX Development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 17,
        title: "Custom Blockchain Development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
];

export const AIDataTech = [
    {
        id: 1,
        title: "Machine Learning",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 2,
        title: "Robotics Process Automation",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 3,
        title: "Behaviour Analytics",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 4,
        title: "Chatbot Ai Development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 5,
        title: "Product Recommendation Engine",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 6,
        title: "Image Processing",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 7,
        title: "Predictive Analysis",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },

    {
        id: 8,
        title: "Virtual try-on",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 9,
        title: "Natural Language Processing",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 10,
        title: "ChatGpt Application development",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 11,
        title: "Data Science Consultation",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
    {
        id: 12,
        title: "Video Analysis",
        hourlyData: [
            {
                id: 1,
                title: "For 40 Hours",
                hour: "from $28/ hr",
                days: "*Validity 30 days",
            },
            {
                id: 2,
                title: "For 80 Hours",
                hour: "from $25/ hr",
                days: "*Validity 90 days",
            },
            {
                id: 3,
                title: "For 120 Hours",
                hour: "from $22/ hr",
                days: "*Validity 120 days",
            },
        ],
        monthlyData: [
            {
                id: 1,
                title: "Junior Dev",
                hour: "from $2000/ mo",
                experience: "1-2 years Experienced",
            },
            {
                id: 2,
                title: "Mid Dev",
                hour: "from $2500/ mo",
                experience: "2-3 years Experienced",
            },
            {
                id: 3,
                title: "Senior Dev",
                hour: "from $2800/ mo",
                experience: "3-5 years Experienced",
            },
            {
                id: 4,
                title: "Associate Technical Head",
                hour: "from $3500/ mo",
                experience: "5-7 years Experienced",
            },
            {
                id: 5,
                title: "Technical Head",
                hour: "from $3800/ mo",
                experience: "8+ years Experienced",
            },
        ],
    },
];
