"use client";

import React from "react";
import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Pushlogo from "../../../public/Img/Home/Pushlogo.svg";
import EducationSectorSideImg from "../../../public/Img/Home/EducationSectorSideImg.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import Link from "next/link";

const EducationSector = () => {
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const content = {
        title: "Our Latest Projects",
        description: "",
        data: [
            {
                id: 1,
                logo: Pushlogo,
                logoName: "Push",
                category: "Blockchain",
                description:
                    "The Web3 communication protocol, exemplified by PUSH (Ethereum Push Notification Service), enables decentralized notifications. This technology allows decentralized applications (dApps) to send alerts directly to users' wallets, greatly enhancing user engagement and the overall user experience.",
                btnName: "View More",
                btnLink: "https://www.tanthetaa.com/portfolio/Webapp-like-push",
                sideImg: EducationSectorSideImg,
            },
            // {
            //     id: 2,
            //     logo: Pushlogo,
            //     logoName: "Push",
            //     category: "Blockchain",
            //     description:
            //         "The Web3 communication protocol, exemplified by PUSH (Ethereum Push Notification Service), enables decentralized notifications. This technology allows decentralized applications (dApps) to send alerts directly to users' wallets, greatly enhancing user engagement and the overall user experience.",
            //     btnName: "View More",
            //     btnLink: "https://www.tanthetaa.com/portfolio/Webapp-like-push",
            //     sideImg: EducationSectorSideImg,
            // },
            // {
            //     id: 3,
            //     logo: Pushlogo,
            //     logoName: "Push",
            //     category: "Blockchain",
            //     description:
            //         "The Web3 communication protocol, exemplified by PUSH (Ethereum Push Notification Service), enables decentralized notifications. This technology allows decentralized applications (dApps) to send alerts directly to users' wallets, greatly enhancing user engagement and the overall user experience.",
            //     btnName: "View More",
            //     btnLink: "https://www.tanthetaa.com/portfolio/Webapp-like-push",
            //     sideImg: EducationSectorSideImg,
            // },
        ],
    };

    return (
        <>
            <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold pb-7 text-center font-manrope">
                {content?.title}
            </h2>
            <div className="bg-[#FFD4FF] ">
                <div className="py-20 px-5 container m-auto">
                    <Slider {...settings} className="z-0">
                        {content?.data?.map((item, index) => {
                            return (
                                <div
                                    className="!grid lg:!grid-cols-2 lg:gap-10 place-items-center"
                                    key={index}
                                >
                                    <div className="px-4">
                                        <Image
                                            src={item?.logo}
                                            alt={item?.logo}
                                        />
                                        <div className="flex flex-col gap-2 mt-6">
                                            <h3 className="font-manrope font-extrabold text-4xl leading-10">
                                                {item?.logoName}
                                            </h3>
                                            <span className="text-[#410EAD] font-inter font-medium text-lg leading-5">
                                                {item?.category}
                                            </span>
                                            <p className="font-inter font-medium text-lg leading-6 tracking-wide">
                                                {item?.description}
                                            </p>
                                            <Link
                                                href={item?.btnLink}
                                                className="bg-[#8148E4] hover:bg-[#29e248] text-white hover:text-black ease-in-out flex flex-row justify-center items-center gap-2 font-manrope font-semibold tracking-wide text-lg leading-6 w-40 my-4 py-2 px-2.5 rounded-md"
                                            >
                                                <span> {item?.btnName}</span>
                                                <FaArrowRightLong />
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <Image
                                            src={item?.sideImg}
                                            alt="Education Sector SideImg"
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default EducationSector;
