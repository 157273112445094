"use client";
import React from "react";
import Image from "next/image";

import Button from "../../../components/ui/common/Button/page";
import Opensea from "@/public/Img/Home/opensea.png";
import Layer from "@/public/Img/Home/layer.png";
import Li from "@/public/Img/Home/li.png";
import Gmx from "@/public/Img/Home/gmx.png";
import curve from "@/public/Img/Home/curve.png";
import Caldela from "@/public/Img/Home/caldela.png";

import "../Marquees/Marquees.css";
import Marquee from "react-fast-marquee";

const Enterprise = () => {
    const marqueeData = [
        {
            id: 1,
            marqueeImage: Opensea,
            imageAlt: "Opensea",
        },
        {
            id: 2,
            marqueeImage: Layer,
            imageAlt: "Layer",
        },
        {
            id: 3,
            marqueeImage: Li,
            imageAlt: "Li",
        },
        {
            id: 4,
            marqueeImage: Gmx,
            imageAlt: "Gmx",
        },
        {
            id: 5,
            marqueeImage: curve,
            imageAlt: "curve",
        },
        {
            id: 6,
            marqueeImage: Caldela,
            imageAlt: "Caldela",
        },
    ];

    return (
        <>
            <div
                className="container mx-auto p-6 bg-[#FFFFFF] rounded-xl  my-20"
                style={{
                    boxShadow: "0px 0px 38px 0px #7B3FE429",
                }}
            >
                <div className="bg-center rounded-2xl lg:mx-20 md:mx-20  bg-transparent  h-min w-auto bg-[url('/Img/Home/enterprise.jpg')]">
                    <div className="grid lg:grid-cols-12 lg:p-10 md:p-10 ">
                        <div className="lg:col-span-4 text-[#ffff] h-auto lg:text-start md:text-center text-center mt-5">
                            <div className="lg:text-4xl md:text-4xl text-3xl font-bold">
                                Enterprise
                            </div>
                            <div className="lg:text-xl md:text-xl text-sm mt-5  ">
                                Volume pricing, unmatched <br /> support, custom
                                SLAs.
                            </div>
                            <div
                            // onClick={() => {
                            //     window.scrollTo({
                            //         top: 0,
                            //         left: 0,
                            //         behavior: "smooth",
                            //     });
                            // }}
                            >
                                <Button className="btn-primary-purple w-fit p-4 mt-5 ">
                                    Initiate a Partnership
                                </Button>
                            </div>
                        </div>
                        <div className="lg:col-span-8 grid-rows-1  text-[#FFFFFF] lg:text-start  md:mt-5 lg:mt-0 mt-5 ml-5 xl:ml-0 mb-5">
                            <div className="lg:grid lg:grid-cols-2  md:gap-5 xl:gap-0 md:mx-14 md:text-xl lg:mx-0">
                                <div className="lg:mx-0  md:mt-5 lg:ml-0  mx-5 mt-2">
                                    24/7 engineering team access
                                </div>
                                <div className="lg:mx-0  md:mt-5 lg:ml-0  mx-5 mt-2">
                                    Committed usage discounts
                                </div>
                            </div>
                            <div className="lg:grid lg:grid-cols-2 md:mt-5 lg:mt-5 md:gap-5 xl:gap-0 md:mx-14 md:text-xl lg:mx-0">
                                <div className="lg:mx-0   md:mt-5 lg:ml-0  mx-5 mt-2">
                                    Custom On Demand discounts
                                </div>
                                <div className="lg:mx-0   md:mt-5 lg:ml-0  mx-5 mt-2">
                                    Custom throughput
                                </div>
                            </div>
                            <div className="lg:grid lg:grid-cols-2 md:mt-5 lg:mt-5 md:gap-5 xl:gap-0 md:mx-14 md:text-xl lg:mx-0">
                                <div className="lg:mx-0   md:mt-5 lg:ml-0  mx-5 mt-2">
                                    24/7 VIP support channel
                                </div>
                                <div className="lg:mx-0   md:mt-5 lg:ml-0  mx-5 mt-2">
                                    Usage optimization reviews
                                </div>
                            </div>
                            <div className="lg:grid lg:grid-cols-2 md:mt-5 lg:mt-5 md:gap-5 xl:gap-0 md:mx-14 md:text-xl lg:mx-0">
                                <div className="lg:mx-0   md:mt-5 lg:ml-0  mx-5 mt-2">
                                    Guaranteed response-time SLAs
                                </div>
                                <div className="lg:mx-0   md:mt-5 lg:ml-0  mx-5 mt-2">
                                    Pay in Crypto
                                </div>
                            </div>
                            <div className="lg:grid lg:grid-cols-2 md:mt-5 lg:mt-5 md:gap-5 xl:gap-0 md:mx-14 md:text-xl lg:mx-0">
                                <div className="lg:mx-0   md:mt-5 lg:ml-0  mx-5 mt-2">
                                    SSO/Auth Managements access
                                </div>
                                <div className="lg:mx-0   md:mt-5 lg:ml-0  mx-5 mt-2">
                                    Marketing support via Amplify Pro
                                </div>
                            </div>

                            <div className="lg:grid lg:grid-cols-2 md:mt-5 lg:mt-5 md:gap-5 xl:gap-0 md:mx-14 md:text-xl lg:mx-0">
                                <div className="lg:mx-0    md:mt-5 lg:ml-0  ml-5 mt-2">
                                    Prioritized alpha/beta access
                                </div>
                                <div className="lg:mx-0    md:mt-5 lg:ml-0  ml-5 mt-2">
                                    Custom terms
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 xl:mx-36 lg:mx-28 md:mx-16">
                    <Marquee speed="10">
                        {marqueeData.map((element) => {
                            return (
                                <div className="mx-3 lg:mx-11" key={element.id}>
                                    <Image
                                        src={element.marqueeImage}
                                        alt={element.imageAlt}
                                    />
                                </div>
                            );
                        })}
                    </Marquee>
                </div>
                {/* <div className="lg:mx-20 md:mx-20 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-6 my-10 gap-5">
                    <Image
                        src={Opensea}
                        alt="Opensea"
                        className="xl:w-36 md:w-20 w-auto h-8"
                    />
                    <Image
                        src={Layer}
                        alt="Layer"
                        className="xl:w-36 md:w-20 w-auto h-8"
                    />
                    <Image
                        src={Li}
                        alt="Li"
                        className="xl:w-36 md:w-20 w-auto h-8"
                    />
                    <Image
                        src={Gmx}
                        alt="Gmx"
                        className="xl:w-36 md:w-20 w-auto h-8"
                    />
                    <Image
                        src={curve}
                        alt="curve"
                        className="xl:w-36 md:w-20 w-auto h-8"
                    />
                    <Image
                        src={Caldela}
                        alt="Caldela"
                        className="xl:w-36 md:w-20 w-auto h-8"
                    />
                </div> */}
                {/* <div className='lg:mx-20 md:mx-20 grid lg:grid-cols-6 md:grid-cols-6 grid-cols-3 my-10 gap-5'>
                    <Image src={Opensea} alt="Opensea" className='xl:w-36 md:w-20 w-14 h-10' />
                    <Image src={Layer} alt="Layer" className='xl:w-36 md:w-20 w-14 h-10' />
                    <Image src={Li} alt="Li" className='xl:w-36 md:w-20 w-14 h-10' />
                    <Image src={Gmx} alt="Gmx" className='xl:w-36 md:w-20 w-14 h-10' />
                    <Image src={curve} alt="curve" className='xl:w-36 md:w-20 w-14 h-10' />
                    <Image src={Caldela} alt="Caldela" className='xl:w-36 md:w-20 w-14 h-10' />

                </div> */}
            </div>
        </>
    );
};

export default Enterprise;
