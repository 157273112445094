export const NFT = `

// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;

import "@openzeppelin/contracts/token/ERC721/ERC721.sol";
import "@openzeppelin/contracts/access/Ownable.sol";


contract MyNFT is ERC721, Ownable {
    uint256 public nextTokenId;
    address public admin;

    constructor() ERC721("MyNFT", "MNFT") {
        admin = msg.sender;
    }

    function mint(address to) external onlyOwner {
        _safeMint(to, nextTokenId);
        nextTokenId++;
    }
}
`;

export const DEFI = `

const { ethers } = require('ethers');

// Connect to Ethereum mainnet or testnet (e.g., via Infura)
const provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID");

// Define wallet private key (Use .env or other secure storage in real applications)
const privateKey = "YOUR_PRIVATE_KEY";
const wallet = new ethers.Wallet(privateKey, provider);

// Uniswap V2 Router Contract Address (Ethereum Mainnet)
const uniswapRouterAddress = "0x7a250d5630b4cf539739dsdf2c5dAcb4c659F2488D";

// Uniswap V2 Router ABI (simplified for swapping functions)
const uniswapRouterABI = [
    "function getAmountsOut(uint amountIn, address[] memory path) public view returns (uint[] memory amounts)",
    "function swapExactTokensForTokens(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)"
];
`;

export const WalletDevelopment = `

const { ethers } = require('ethers');

// Connect to the Ethereum network (e.g., using Infura)
const provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID");

// Generate a new wallet
async function createWallet() {
    const wallet = ethers.Wallet.createRandom();
    const privateKey = wallet.privateKey;
    const address = wallet.address;

    console.log(\`New Ethereum Wallet Address: \${address}\`);
    console.log(\`Private Key (Keep it secret!): \${privateKey}\`);

    // Check the balance of the newly created wallet
    await checkBalance(address);
}
`;

export const UserOps = `

// Approve DAI spending by the contract
async function approveDAISpending() {
    try {
        // Call the 'approve' function of the ERC-20 contract
        const tx = await daiContract.approve(spenderAddress, amountToApprove);
        console.log('Approval transaction sent:', tx.hash);


        // Wait for the transaction to be mined
        const receipt = await tx.wait();
        console.log('Approval transaction confirmed in block:', receipt.blockNumber);
    } catch (error) {
        console.error('Error approving tokens:', error);
    }
}
`;

export const DApps = `

// Send ETH to another address
async function sendETH() {
    const recipientAddress = document.getElementById('recipientAddress').value;
    const amount = document.getElementById('sendAmount').value;

    if (!recipientAddress || !amount) {

    
        alert('Please enter a recipient address and amount.');
        return;
    }

    try {
        const tx = await signer.sendTransaction({
            to: recipientAddress,
            value: ethers.utils.parseEther(amount)
        });

        console.log('Transaction sent:', tx.hash);
        alert(\`Transaction sent! Hash: \${tx.hash}\`);
    } catch (error) {
        console.error('Error sending ETH:', error);
    }
}
`;
